<template>
  <svg
    class="loader"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    style="background: #fff; display: block;"
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <g transform="translate(50 50)">
      <g transform="translate(-19 -19) scale(0.6)">
        <g transform="rotate(44.5711)">
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="0;45"
            keyTimes="0;1"
            dur="0.2s"
            begin="0s"
            repeatCount="indefinite"
          ></animateTransform>
          <path
            d="M31.359972760794346 21.46047782418268 L40.55236091621946 30.652865979607796 L30.652865979607796 40.55236091621946 L21.46047782418268 31.359972760794346 A38 38 0 0 1 7.0000000000000036 37.3496987939662 L7.0000000000000036 37.3496987939662 L7.000000000000004 50.3496987939662 L-6.999999999999999 50.3496987939662 L-7 37.3496987939662 A38 38 0 0 1 -21.46047782418268 31.35997276079435 L-21.46047782418268 31.35997276079435 L-30.652865979607796 40.55236091621947 L-40.55236091621946 30.652865979607803 L-31.359972760794346 21.460477824182682 A38 38 0 0 1 -37.3496987939662 7.000000000000007 L-37.3496987939662 7.000000000000007 L-50.3496987939662 7.000000000000009 L-50.3496987939662 -6.999999999999996 L-37.3496987939662 -6.999999999999997 A38 38 0 0 1 -31.35997276079435 -21.460477824182675 L-31.35997276079435 -21.460477824182675 L-40.55236091621947 -30.65286597960779 L-30.652865979607824 -40.55236091621944 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999995 -50.3496987939662 L6.999999999999976 -50.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L30.652865979607803 -40.55236091621946 L40.55236091621945 -30.652865979607817 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L50.3496987939662 -6.999999999999998 L50.349698793966205 6.9999999999999725 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182686 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
            fill="#050404"
          ></path>
        </g>
      </g>
      <g transform="translate(19 19) scale(0.6)">
        <g transform="rotate(22.9289)">
          <animateTransform
            attributeName="transform"
            type="rotate"
            values="45;0"
            keyTimes="0;1"
            dur="0.2s"
            begin="-0.1s"
            repeatCount="indefinite"
          ></animateTransform>
          <path
            d="M-31.35997276079435 -21.460477824182675 L-40.55236091621947 -30.65286597960779 L-30.652865979607824 -40.55236091621944 L-21.4604778241827 -31.35997276079433 A38 38 0 0 1 -6.999999999999992 -37.3496987939662 L-6.999999999999992 -37.3496987939662 L-6.999999999999995 -50.3496987939662 L6.999999999999976 -50.3496987939662 L6.999999999999979 -37.3496987939662 A38 38 0 0 1 21.460477824182686 -31.359972760794342 L21.460477824182686 -31.359972760794342 L30.652865979607803 -40.55236091621946 L40.55236091621945 -30.652865979607817 L31.35997276079433 -21.4604778241827 A38 38 0 0 1 37.3496987939662 -6.999999999999995 L37.3496987939662 -6.999999999999995 L50.3496987939662 -6.999999999999998 L50.349698793966205 6.9999999999999725 L37.349698793966205 6.999999999999976 A38 38 0 0 1 31.359972760794346 21.460477824182686 L31.359972760794346 21.460477824182686 L40.55236091621946 30.6528659796078 L30.652865979607824 40.55236091621944 L21.460477824182703 31.35997276079433 A38 38 0 0 1 6.9999999999999964 37.3496987939662 L6.9999999999999964 37.3496987939662 L6.999999999999995 50.3496987939662 L-7.000000000000009 50.3496987939662 L-7.000000000000007 37.3496987939662 A38 38 0 0 1 -21.46047782418263 31.359972760794385 L-21.46047782418263 31.359972760794385 L-30.65286597960774 40.55236091621951 L-40.55236091621944 30.65286597960783 L-31.35997276079433 21.460477824182703 A38 38 0 0 1 -37.34969879396619 7.000000000000032 L-37.34969879396619 7.000000000000032 L-50.34969879396619 7.000000000000036 L-50.3496987939662 -7.000000000000001 L-37.3496987939662 -7.000000000000005 A38 38 0 0 1 -31.359972760794346 -21.46047782418268 M0 -23A23 23 0 1 0 0 23 A23 23 0 1 0 0 -23"
            fill="#8d8b89"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
svg {
  margin: 2rem auto;
}
</style>
