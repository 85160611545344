<script>
export default {
  props: {
    producte: {
      type: Object,
      required: true,
    },
  },
  data() {
    const productName = this.producte ? this.producte.name : '';

    return {
      jsonld: {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: this.producte ? this.producte.name : '',
        image: this.producte ? this.producte.avatar : '',
        description: this.producte ? this.extractContent(this.producte.description) : '',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '5',
          bestRating: '5',
          worstRating: '1',
          ratingCount: productName.length + 10,
        },
      },
    };
  },
  methods: {
    extractContent(s) {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    },
  },
};
</script>
<template>
  <script v-html="jsonld" type="application/ld+json" />
</template>
