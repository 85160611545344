<template>
  <div id="app-container">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Maquinaria metalurgica de ocasion',
    // all titles will be injected into this template
    titleTemplate: 'ℛ Maquinaria Reig ⚙️ %s ',
  },
};
</script>
<style lang="scss">
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  color: #00b8ff;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

body {
  font-size: 1rem;
  //font-family: "Trebuchet MS", Helvetica, sans-serif;//'Ubuntu', sans-serif;
  font-family: "Arial", sans-serif;
  color: #333;
  text-align: center;
}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3 {
  font-family: "Helvetica", sans-serif;
}

header {
  height: 10%;
  width: 100%;
  font-size: 3rem;
  border-bottom: 2px solid #000;
  box-shadow: 1px 1px 1px 1px #bf8d1d;
  background: rgb(52, 58, 64);
  background: linear-gradient(0deg, #343a40 0%, #000 75%, #000 86%, #000 100%);
}

footer {
  bottom: 0;
  text-align: center;
  width: 100%;
  padding: 0.1rem;
  font-weight: 600;
  font-size: 1.1rem;
  //font-family: "Trebuchet MS", Helvetica, sans-serif;//'Ubuntu', sans-serif;
  position: static;
  z-index: 99;
  background: #fff;
  background: #ffffff9e;
}
#logo-container {
  min-height: 125px;
}
header img {
  width: 100%;
  height: auto;
  max-height: 300px;
  min-height: 140px;
  object-fit: cover;
  max-width: 1000px;
}

h1 {
  //font-family: "Trebuchet MS", Helvetica, sans-serif;//'Ubuntu', sans-serif;
  font-weight: 600;
  color: #333;
  margin: 1rem;
  text-transform: uppercase;
}

h4,
footer {
  color: #333;
}

a:hover {
  text-decoration: none;
}

button {
  background: #fff;
  border: 5px solid #000;
  border-radius: 25px;
  font-size: 1.5rem;
  font-weight: 800;

  //background: #ff5e00;
  //padding: 0.5rem;

  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
}

button:not(.close):hover {
  background-color: #0056b3;
  transition: background-color 0.3s ease;
}

.fade-in {
  animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.back-button {
  float: left;
  margin-left: 3.5rem;
  font-weight: 800;
  font-size: 2rem;
  border: 1px solid #c3b8b8;
  padding: 0.2rem 0.4rem;
  cursor: pointer;
  margin-top: 2rem;
}

button:hover {
  cursor: pointer;
}

.card-deck.main-deck {
  align-items: center;
  justify-content: center;
}

.card-deck.main-deck .card {
  flex: unset !important;
  padding-bottom: 0;
}

.card-deck.main-deck .card .card-text {
  position: relative;
  padding: 0.5rem;
}

.card {
  /*max-height: 250px;*/
  z-index: 2;
  min-width: 13rem;
  /*max-width: 18rem;*/
  border: none;
  /*border: 4px solid #000;*/
  /*box-shadow: 5px 5px 10px 1px #905f5f;*/
  /*box-shadow: 5px 5px 10px 1px #28263a; */
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  overflow: hidden;
}

.card:hover {
  z-index: 3;
  transform: scale(1.05);
  max-height: 1000px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.card img {
  border: none;
  padding: 0;
  position: relative;
  margin-bottom: -35px;
}

.card-body {
  text-align: center;
  padding: 0;
}

body a.mainLink {
  text-transform: uppercase;
  padding: 1rem;
  border: 5px solid #000;
  border-radius: 5%;
  color: #333;
  width: 50vw;
  height: 50vw;
}

.card-deck {
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
  max-width: 1500px;
  z-index: 1;
}

.card-text {
  color: #333;
  font-weight: 600;
  margin: 0 auto;
  font-size: 16px;
  z-index: 2;
  text-transform: capitalize;
  background: #ffffffbf;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 0.3em 0.1em;
}

.producte img {
  top: 0;
  left: 0;
}

.producte p {
  float: left;
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  font-size: 1rem;
}

.zoom-on-hover {
  position: relative;
  overflow: hidden;
  max-width: 48%;
  float: left;
}

.zoom-on-hover .normal {
  width: 100%;
}

.zoom-on-hover .zoom {
  position: absolute;
  opacity: 0;
  transform-origin: top left;
}

.main-link {
  font-size: 1.2rem;
  font-weight: 800;
  min-height: 55px;
}

.description {
  float: left;
  margin: 1rem 1rem 0;
  max-width: 48%;
}

#gallery-1 {
  display: none;
}

.zoom-on-hover img {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.card {
  text-align: center;
  margin: 0 auto;
  max-width: 250px;
}

.ocasion .card {
  display: none;
}

.nueva [data-category="maquinaria-ocasion"],
.nueva [data-sub-category="maquinaria-ocasion"] {
  display: none;
}

.ocasion .card[data-category="maquinaria-ocasion"],
.ocasion .card[data-sub-category="maquinaria-ocasion"] {
  display: flex;
}

.ocasion,
.nueva {
  text-align: center;
  margin: 0 auto;
}

.hidden {
  display: none !important;
}

.producte {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin: 2rem;
  min-height: 500px;
}

.producte:after {
  content: " ";
  display: block;
  height: 0;
  clear: both;
}

.zoom-on-hover .normal {
  padding: 1px;
  border: 1px solid #021a40;
}

h2 {
  font-weight: 600;
  color: #333;
  font-size: 1.8rem;
  margin: 2rem 1rem;
  //font-family: "Trebuchet MS", Helvetica, sans-serif;//'Ubuntu', sans-serif;
  text-align: center;
}

.main-deck img {
  max-height: 300px;
  margin-bottom: 0 !important;
  border-radius: 0;
  object-fit: cover;
}

.main-deck .card {
  width: 30vw;
  max-width: 400px;
  align-self: stretch;
}
@media (max-width: 800px) {
  .zoom-on-hover,
  .description {
    max-width: 100%;
  }

  .main-deck .card {
    min-width: 30%;
    margin: 0 10px;
  }

  .main-deck .card p {
    font-size: 14px;
  }

  header img {
    width: 100%;
  }

  .card-deck.main-deck .card {
    //flex: unset !important;
    padding-bottom: 0;
    min-width: 50%;
    margin: 0 10px;
    height: 250px !important;
  }

  .card-deck.main-deck .card .card-text {
    padding: 0 5px;
  }
}
@media (max-width: 550px) {
  .main-link.ocasion-link {
    min-height: 35px;
  }
}

header img {
  /*  background: #ffffff4d;
     border-radius: 10px;*/
}

#logo-container {
  /*background: url(http://127.0.0.1:8080/img/capcelera.png) no-repeat center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;*/
}

.text-footer {
  min-height: 150px;
  font-size: 0.9rem;
  padding: 0 20px;
}

.image {
  width: 100px;
  height: 100px;
  background-size: contain;
  cursor: pointer;
  margin: 10px;
  border-radius: 3px;
}

.images-container {
  float: right;
}

.back-button {
  width: 30px;
  height: 30px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: #343a40 1px solid;
  overflow: hidden;
  transition: background 0.3s ease;
}
.back-button.back .arrow-wrap {
  left: -50%;
}
.back-button:hover {
  background: #343a40;
}
.back-button:hover .arrow-wrap span {
  background: #fff;
}
.back-button .arrow-wrap {
  display: block;
  position: absolute;
  height: 60%;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s ease;
}
.back-button .arrow-wrap span {
  height: 1px;
  left: 0;
  top: 50%;
  background: #343a40;
  position: absolute;
  display: block;
  transition: background 0.3s ease;
}
.back-button .arrow-wrap .arrow-part-1 {
  width: 100%;
  transform: translate(0, -50%);
}
.back-button .arrow-wrap .arrow-part-2 {
  width: 60%;
  transform: rotate(-45deg);
  transform-origin: 0 0;
}
.back-button .arrow-wrap .arrow-part-3 {
  width: 60%;
  transform: rotate(45deg);
  transform-origin: 0 0;
}

fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

#navbarDropdown,
#navbarDropdown2 {
  cursor: pointer;
  color: #fff;
}

.dropdown-item.router-link-exact-active {
  font-weight: 800;
}

/* from */
input[name="your-maquina"] {
  display: none;
}

.modal * {
  text-align: left;
}

.modal label {
  margin-bottom: .25rem;
}

.modal p {
  margin: 0;
}

.triggerButton {
  display: block;
  margin: 3em auto;
  padding: 1rem;
  padding: 0.5rem 2rem;
  //background: #008000;
  background: #ff5e00;
  font-weight: 600;
  border: none;
}

.triggerButton--last {
  background: #089474;
}

.triggerButton:hover {
  background: #06808c;
}

label {
  display: inline;
}

.form-error {
  color: #f00;
  font-size: 0.8rem;
}

/* test */
/*
.producte p {
    float: right;
    margin-bottom: 1rem;
    width: 80%;
    text-align: left;
    font-size: 1rem;
    text-transform: uppercase;
    color: #000;
    line-height: 0em;
    text-align: center;
    font-size: 2rem;
    font-weight: 800;
    padding-top: 40px;
}
.producte {
  margin-top: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin: 1rem;
    background: #d3d3d3;
}

.producte img {
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    float: left;
    border: 3px solid #ffa500;
} */

#app-container {
  min-height: 100vh;
}

.row {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  justify-content: space-evenly;
}
.modal-title {
  font-size: 1.2rem;
}
</style>
