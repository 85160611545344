var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar navbar-expand-lg navbar-dark bg-dark",attrs:{"id":"menu"}},[_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"}),_c('li',{staticClass:"nav-item active dropdown",attrs:{"data-id":"maquinaria-nova2"}},[_c('h2',{staticClass:"nav-link dropdown-toggle",attrs:{"id":"navbarDropdown2","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n          Maquinaria Nueva\n        ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown2"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/maquinaria-nueva/categorias"}},[_vm._v("Maquinaria Nueva")]),_c('div',{staticClass:"dropdown-divider"}),_vm._l((_vm.newCategories),function(newCategory,index){return _c('router-link',{key:index,staticClass:"dropdown-item",attrs:{"to":{
              name: 'category',
              params: { parentCategory: 'maquinaria-nueva', childCategory: newCategory.slug },
            }}},[_vm._v(_vm._s(newCategory.name[0].toUpperCase() + newCategory.name.slice(1).toLowerCase()))])})],2)]),_c('li',{staticClass:"nav-item dropdown",attrs:{"data-id":"maquinaria-ocasion"}},[_c('h2',{staticClass:"nav-link dropdown-toggle",attrs:{"id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n          Maquinaria de Ocasion\n        ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":"/maquinaria-ocasion/categorias"}},[_vm._v("Maquinaria de Ocasion")]),_c('div',{staticClass:"dropdown-divider"}),_vm._l((_vm.oldCategories),function(oldCategory,index){return _c('router-link',{key:index,staticClass:"dropdown-item",attrs:{"to":{
              name: 'category',
              params: { parentCategory: 'maquinaria-ocasion', childCategory: oldCategory.slug },
            }}},[_vm._v(_vm._s(oldCategory.name[0].toUpperCase() + oldCategory.name.slice(1).toLowerCase()))])})],2)])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchValue),expression:"searchValue"}],staticClass:"form-control search-input mr-sm-2",attrs:{"type":"search","placeholder":"Buscar","aria-label":"Search","data-search":""},domProps:{"value":(_vm.searchValue)},on:{"input":function($event){if($event.target.composing)return;_vm.searchValue=$event.target.value}}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }