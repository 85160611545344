import Vue from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

// Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(VueGtag, {
  config: { id: 'G-HHY14DKJ8C' },
}, router);

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    if (this.$route.params.productId) {
      this.$store.dispatch('fetchProduct', this.$route.params.productId);
    }
    this.$store.dispatch('fetchProducts');
  },
}).$mount('#app-container');

fetch('https://maquinariareig.com/wp-json/my-route/my-phrase')
  .then(response => response.json())
  .then((form) => {
    const modal = document.querySelector('.modal');
    if (modal) {
      modal.remove();
    }
    const modalBackdrop = document.querySelector('.modal-backdrop');
    if (modalBackdrop) {
      modalBackdrop.remove();
    }
    Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
    const el = document.createElement('div');
    el.id = 'modalWrapper';
    document.body.appendChild(el);

    const modalContent = `${
      '<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">'
        + '<div class="modal-dialog">'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<h4 class="modal-title">'
    }Consulta a Maquinaria Reig</h4>`

      + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
      + '</div>'
      + `<div class="modal-body">${form}</div>`
      + '</div>'
      + '</div>'
      + '</div>';

    el.innerHTML = modalContent;

    const formModal = document.getElementById('wpcf7-f3-o1');
    formModal.action = 'https://maquinariareig.com/wp-json/contact-form-7/v1/contact-forms/3/feedback';

    function addSubscriber() {
      const checkbox = document.getElementById('newsletter');
      if (checkbox.checked) {
        const email = document.querySelector('[name="your-email"]');
        if (email) {
          const url = 'https://maquinariareig.com/wp-json/newsletter/v2/subscribers?client_key=4ebb5b2b007eaa28da0b123e94c5eb5aa7cd9ad9&client_secret=d91a22294d77af87e9e67266e322d78d13c088d5';
          const data = {
            email: email.value,
            status: 'confirmed'
          };

          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
          })
            .then(response => response.json())
            .then(data => console.log('Success:', data))
            .catch((error) => console.error('Error:', error));
        }
      }
    }

    formModal.addEventListener('submit', (evt) => {
      evt.preventDefault();
      const url = formModal.action;
      const formData = new FormData(document.querySelector('form'));

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then((data) => {
          if (data.status === 'mail_sent') {
            if (window.$gtag) {
              window.$gtag.event('send', { formulari: 'submit' });
            }
            //
            // if (window.ga) {
            //   window.ga("send", "event", "Formulari", "submit");
            // }

            const modal = document.querySelector('.modal');
            if (modal) {
              modal.remove();
            }
            const modalBackdrop = document.querySelector('.modal-backdrop');
            if (modalBackdrop) {
              modalBackdrop.remove();
            }
            Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
          } else {
            const oldErrors = document.querySelectorAll('.form-error');
            for (let i = 0; i < oldErrors.length; i++) {
              oldErrors[i].remove();
            }

            if (data.invalid_fields) {
              for (let i = 0; i < data.invalid_fields.length; i++) {
                const field = document.querySelector('[name=' + data.invalid_fields[i].field + ']');
                if (field) {
                  const divElement = document.createElement('div');
                  divElement.className = 'form-error';
                  divElement.innerHTML = data.invalid_fields[i].message;
                  field.insertAdjacentElement('afterend', divElement);
                }
              }
            }
          }
        });

      addSubscriber();
    });
  })
  .catch(error => console.error(error));
