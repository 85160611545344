import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

function getParentCategory(product) {
  const categories = product.pure_taxonomies.categories_productes;

  for (let i = 0; i < categories.length; i++) {
    const nom = categories[i].name.toLowerCase();
    if (nom === 'maquinaria nueva' || nom === 'maquinaria de ocasión') {
      return categories[i];
    }
  }

  return { name: null };
}

function getCategory(product) {
  const categories = product.pure_taxonomies.categories_productes;

  for (let i = 0; i < categories.length; i++) {
    if (
      categories[i].name.toLowerCase() !== 'maquinaria nueva'
      && categories[i].name.toLowerCase() !== 'maquinaria de ocasión'
    ) {
      return categories[i];
    }
  }

  return { name: null };
}

export default new Vuex.Store({
  state: {
    loadingProducts: false,
    fetchedPage: 1,
    products: [],
    newCategories: [],
    oldCategories: [],
    headerImage: 'https://maquinariareig.com/wp-content/uploads/2018/10/header.png',
  },
  mutations: {
    ADD_PRODUCTS: (state, newProducts) => {
      const productes = state.products;

      for (let i = 0; i < newProducts.length; i++) {
        const category = getCategory(newProducts[i]);
        const parentCategory = getParentCategory(newProducts[i]);

        if (category.name !== null && parentCategory.name !== null) {
          if (parentCategory.name.toLowerCase() === 'maquinaria nueva') {
            category.avatar = newProducts[i].avatar;
            state.newCategories.push(category);
          } else {
            category.avatar = newProducts[i].avatar;
            state.oldCategories.push(category);
          }

          state.products.push({
            name: newProducts[i].title.rendered,
            description: newProducts[i].content.rendered,
            productId: newProducts[i].id,
            category,
            parentCategory,
            avatar: newProducts[i].avatar,
          });
        }
      }

      // state.products = JSON.parse(JSON.stringify(productes));
    },
    INCREASE_PAGE: (state, newProducts) => {
      state.fetchedPage++;
    },
    TOGGLE_LOADING: (state, value) => {
      state.loadingProducts = value;
    },
  },
  actions: {
    fetchProducts: (context) => {
      context.commit('TOGGLE_LOADING', true);
      const perPage = 50;
      fetch(
        `https://maquinariareig.com/wp-json/wp/v2/productes?page=${
          context.state.fetchedPage
        }&per_page=${perPage}&_embed`, //&test=${Math.floor(Date.now() / 1000)}
      )
        .then(response => response.json())
        .then((data) => {
          context.commit('INCREASE_PAGE', data);

          for (let i = 0; i < data.length; i++) {
            if (data[i].hasOwnProperty('_embedded') && typeof data[i]._embedded !== 'undefined') {
              const mediaData = data[i]._embedded['wp:featuredmedia'][0];
              if (mediaData.media_details.sizes['480x480']) {
                data[i].avatar = mediaData.media_details.sizes['480x480'].source_url;
              } else if (mediaData.media_details.sizes['custom-size']) {
                data[i].avatar = mediaData.media_details.sizes['custom-size'].source_url;
              } else {
                data[i].avatar = mediaData.media_details.sizes.medium.source_url;
              }

              context.commit('ADD_PRODUCTS', [data[i]]);
            }
          }

          if (data.length > perPage - 1) {
            context.dispatch('fetchProducts');
          } else {
            context.commit('TOGGLE_LOADING', false);
          }

          return data.length;
        });
    },
    fetchProduct: (context, productId) => {
      fetch(
        `https://maquinariareig.com/wp-json/wp/v2/productes?_embed&&include[]=${productId}&test=${Math.floor(
          Date.now() / 1000,
        )}`,
      )
        .then(response => response.json())
        .then((data) => {
          for (let i = 0; i < data.length; i++) {
            if (data[i].hasOwnProperty('_embedded') && typeof data[i]._embedded !== 'undefined') {
              const mediaData = data[i]._embedded['wp:featuredmedia'][0];

              if (mediaData.media_details.sizes['480x480']) {
                data[i].avatar = mediaData.media_details.sizes['480x480'].source_url;
              } else if (mediaData.media_details.sizes['custom-size']) {
                data[i].avatar = mediaData.media_details.sizes['custom-size'].source_url;
              } else {
                data[i].avatar = mediaData.media_details.sizes.medium.source_url;
              }

              context.commit('ADD_PRODUCTS', [data[i]]);
            } else {
              console.log(data);
            }
          }

          if (data.length > 99) {
            setTimeout(() => {
              context.dispatch('fetchProducts');
            }, 100);
          }

          return data.length;
        });

      // $.get(
      //   `https://maquinariareig.com/wp-json/wp/v2/productes?_embed&&include[]=${productId}&test=${Math.floor(
      //     Date.now() / 1000,
      //   )}`,
      // ).then((data) => {
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].hasOwnProperty('_embedded') && typeof data[i]._embedded !== 'undefined') {
      //       const mediaData = data[i]._embedded['wp:featuredmedia'][0];
      //
      //       if (mediaData.media_details.sizes['480x480']) {
      //         data[i].avatar = mediaData.media_details.sizes['480x480'].source_url;
      //       } else if (mediaData.media_details.sizes['custom-size']) {
      //         data[i].avatar = mediaData.media_details.sizes['custom-size'].source_url;
      //       } else {
      //         data[i].avatar = mediaData.media_details.sizes.medium.source_url;
      //       }
      //
      //       context.commit('ADD_PRODUCTS', [data[i]]);
      //     } else {
      //       console.log(data);
      //     }
      //   }
      //
      //   if (data.length > 99) {
      //     setTimeout(() => {
      //       context.dispatch('fetchProducts');
      //     }, 100);
      //   }
      //
      //   return data.length;
      // });
    },
  },
});
