<template>
  <div>
    <h1>MAQUINARIA NUEVA</h1>
    <div class="row">
      <category-rectangle
        v-for="(newCategory, index) in newCategories"
        :key="index"
        :name="newCategory.name"
        :avatar="newCategory.avatar"
        :parentCategory="'maquinaria-nueva'"
        :slug="newCategory.slug"
      ></category-rectangle>
    </div>
    <loader v-if="!newCategories.length || loadingProducts"></loader>
  </div>
</template>

<script>
import CategoryRectangle from '@/components/CategoryRectangle';
import Loader from '@/components/Loader';

export default {
  components: {
    'category-rectangle': CategoryRectangle,
    Loader,
  },
  metaInfo: {
    title: 'Maquinaria Nueva',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'En Maquinaria Reig encontrará toda la maquinaria metalurgica nueva que busque. No dude en solicitarnos presupuesto.',
      },
    ],
  },
  name: 'NewMachines',
  computed: {
    loadingProducts() {
      return this.$store.state.loadingProducts;
    },
    newCategories() {
      const output = [];
      const keys = [];

      this.$store.state.newCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
  },
};
</script>
