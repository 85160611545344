<template>
  <div>
    <h1>Resultados de la busqueda</h1>
    <div class="card-deck">
      <product-square
        v-for="(producte, index) in productes"
        :key="index"
        :name="producte.name"
        :avatar="producte.avatar"
        :productId="producte.productId"
      ></product-square>
    </div>
    <div class="no-results" v-if="!productes.length">
      Lo sentimos, ahora mismo no tenemos el producto que buscas pero si nos contactas intentaremos
      encontrarlo por ti!
    </div>
  </div>
</template>
<script>
import ProductSquare from '@/components/ProductSquare';

export default {
  name: 'Search',
  components: {
    'product-square': ProductSquare,
  },
  computed: {
    productes() {
      const context = this;
      const productes = [];
      const fallbackProductes = [];

      const searchParameter = this.$route.params.searchParam;

      if (searchParameter) {
        this.$store.state.products.forEach((post) => {
          if (post.name) {
            const searchTerms = searchParameter.toLowerCase().split(' ');
            let productAdded = false;
            let productFallbackAdded = false;
            searchTerms.forEach((searchTerm) => {
              if (post.name.toLowerCase().indexOf(searchTerm) > -1 && !productAdded) {
                productes.push(post);
                productAdded = true;
              } else if (
                post.category.name.toLowerCase().indexOf(searchTerm) > -1
                && !productAdded
              ) {
                fallbackProductes.push(post);
                productFallbackAdded = true;
              }
            });
          }
        });
      }

      return productes.concat(fallbackProductes);
    },
  },
};
</script>
<style>
.no-results {
  margin-bottom: 500px;
}
</style>
