<template>
  <footer class="site-footer" id="custom-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h3>Nosotros</h3>
          <p class="text-justify">
            En Maquinaria Reig encontrará toda la maquinaria metalurgica que busque ya sea nueva, de
            segunda mano y ocasion. No dude en solicitarnos presupuesto.
          </p>
        </div>

        <div class="col-xs-6 col-md-2">
          <h3>Secciones</h3>
          <ul class="footer-links">
            <li>
              <router-link to="/maquinaria-ocasion/categorias">Maquinaria Ocasion</router-link>
            </li>
            <li><router-link to="/maquinaria-nueva/categorias">Maquinaria Nueva</router-link></li>
          </ul>
        </div>

        <div class="col-xs-6 col-md-2">
          <h3>Destacados</h3>
          <ul class="footer-links">
            <li><router-link to="/categoria/maquinaria-ocasion/tornos">Tornos</router-link></li>
            <li><router-link to="/categoria/maquinaria-ocasion/sierras">Sierras</router-link></li>
            <li>
              <router-link to="/categoria/maquinaria-nueva/prensas-hidraulicas"
                >Prensas Hidraulicas</router-link
              >
            </li>
            <li>
              <router-link to="/categoria/maquinaria-nueva/fresadoras-nueva"
                >Fresadoras</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-9 col-sm-6 col-xs-12">
          <p class="copyright-text">
            Copyright &copy; 2021 <strong>Maquinaria Reig</strong>. Todos los derechos reservados.
          </p>
        </div>

        <div class="col-md-3 col-sm-6 col-xs-12">
          <ul class="social-icons">
            <li>
              <a
                class="google-maps"
                rel="noreferrer"
                target="_blank"
                href="https://goo.gl/maps/Tvz1pRQCFLhNJC6F9"
                ><img
                  width="40px"
                  height="40px"
                  src="https://maquinariareig.com/wp-content/uploads/2020/05/google-maps2.png"
                  alt="google maps"
              /></a>
            </li>
            <li>
              <a
                class="youtube"
                rel="noreferrer"
                target="_blank"
                href="https://www.youtube.com/channel/UCVp8SEMUxgUf2NKMRhvSg3A"
                ><img
                  width="40px"
                  height="40px"
                  src="https://maquinariareig.com/wp-content/uploads/2020/12/pngegg.png"
                  alt="youtube"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss">
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

.site-footer {
  max-width: 100vw;
  background-color: #343a40;
  padding: 1rem 0 0.5rem;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #bfc2c3;
}

.site-footer iframe {
  border: 0px;
  max-width: 300px;
  max-height: 200px;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
  margin: 0.5rem auto;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h3 {
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #bfc2c3;
  padding: 1rem 0.1rem;
}
.site-footer a:hover {
  color: #f59634;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #bfc2c3;
  text-decoration: none;
  font-weight: 400;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #f59634;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: right;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
  padding: 0;
}
.copyright-text {
  margin: 0;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.social-icons img {
  max-width: 100%;
  cursor: pointer;
  position: relative;
  top: -0.1rem;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
</style>
