<template>
  <div>
    <div class="back-button" @click="$router.go(-1)">
      <div class="arrow-wrap">
        <span class="arrow-part-1"></span>
        <span class="arrow-part-2"></span>
        <span class="arrow-part-3"></span>
      </div>
    </div>
    <loader v-if="!producte"></loader>
    <h1 v-if="producte">{{ capitalize(producte.name) }}</h1>
    <button
      v-if="producte"
      type="button"
      data-toggle="modal"
      data-target="#myModal"
      class="btn btn-primary triggerButton"
    >
      CONSULTAR PRECIO
    </button>

    <div v-if="producte" class="producte" v-cloak>
      <img @click="index = 0" :src="producte.avatar" :alt="producte.name" />
      <div class="description">
        <h2 v-if="producte">Características {{ capitalize(producte.name) }}</h2>
        <p class="paragraph-wrapper" v-if="producte.description" v-html="producte.description"></p>
      </div>
      <div class="images-container">
        <img
          class="image"
          v-for="(image, i) in images"
          :src="image"
          @click="index = i"
          :key="image"
        />
      </div>
      <vue-gallery-slideshow
        :images="images"
        :index="index"
        @close="index = null"
      ></vue-gallery-slideshow>
    </div>
    <button
      v-if="producte"
      type="button"
      data-toggle="modal"
      data-target="#myModal"
      class="btn btn-primary triggerButton triggerButton--last"
    >
      CONSULTAR PRECIO
    </button>

    <h3 v-if="producte">
      Mas
      <a
        target="_blank"
        :href="
          'https://maquinariareig.com/categoria/' +
          producte.parentCategory.slug +
          '/' +
          producte.category.slug
        "
        >{{ producte.category.name }}</a
      >
      que podrian interesarte
    </h3>
    <div v-if="producte" class="card-deck">
      <product-square
        v-for="(similarProduct, index) in similarProducts"
        :key="similarProduct.cleanName + index"
        :name="similarProduct.cleanName"
        :avatar="similarProduct.avatar"
        :productId="similarProduct.productId"
      ></product-square>
    </div>

    <data-structure v-if="producte" :producte="producte"></data-structure>
  </div>
</template>

<script>
import VueGallerySlideshow from 'vue-gallery-slideshow';
import Loader from '@/components/Loader';
import DataStructure from '@/components/DataStructure';
import ProductSquare from '@/components/ProductSquare';
import Store from '../store.js';

export default {
  Store,
  name: 'Product',
  components: {
    'vue-gallery-slideshow': VueGallerySlideshow,
    Loader,
    DataStructure,
    'product-square': ProductSquare,
  },
  metaInfo() {
    return {
      title:
        (this.producte ? this.producte.name : 'Maquina')
        + (this.producte && this.producte.parentCategory.slug === 'maquinaria-nueva'
          ? ' al mejor precio'
          : ' de ocasion'),
      meta: [{ vmid: 'description', name: 'description', content: this.metaDescription }],
      link: [
        {
          rel: 'canonical',
          href: this.producte
            ? window.location.href.indexOf(this.producte.name.replace(/ /g, '-')) > -1
              ? window.location.href
              : window.location.href + this.producte.name.replace(/ /g, '-')
            : window.location.href,
        },
      ],
    };
  },
  data() {
    return {
      images: [],
      index: null,
      formUpdated: false,
    };
  },
  mounted() {
    this.fetchGallery(this.$route.params.productId);
  },
  computed: {
    similarProducts() {
      const context = this;
      const productes = [];

      this.$store.state.products.forEach((post) => {
        if (
          post.parentCategory.slug === this.producte.parentCategory.slug
          && post.category.slug === this.producte.category.slug
          && post.productId !== this.producte.productId
        ) {
          post.cleanName = post.name.replace(/\s/g, '-').replace('/', '-');
          productes.push(post);
        }
      });

      return productes;
    },
    metaDescription() {
      const description = '→ En Maquinaria Reig encontrará ';
      if (this.producte) {
        return (
          description
          + this.producte.name
          + (this.producte.parentCategory.slug === 'maquinaria-nueva'
            ? ' al mejor precio. '
            : ' de ocasion. ')
          + this.getMetaDescription()
        );
      }
      return `${description}Maquinas al mejor precio. No dude en solicitarnos presupuesto`;
    },
    producte() {
      const context = this;
      let product = null;

      this.$store.state.products.forEach((post) => {
        if (post.productId == context.$route.params.productId) {
          product = post;
        }
      });

      return product;
    },
  },
  updated() {
    if (this.producte && this.producte.name && this.formUpdated !== this.producte.name) {
      this.fetchForm(this.producte.name);
      this.formUpdated = this.producte.name;
    }
  },
  methods: {
    fetchGallery(producteId) {
      const _this = this;
      return $.get(`https://maquinariareig.com/wp-json/wp/v2/media/?parent=${producteId}`).then(
        (mediaData) => {
          const imagePool = [];
          for (let i = 0; i < mediaData.length; i++) {
            imagePool.push(mediaData[i].source_url);
          }
          _this.images = imagePool;
        },
      );
    },
    getMetaDescription() {
      let metaDescription = this.producte.description;
      metaDescription = metaDescription.replace(/<\/?[^>]+(>|$)/g, '');
      metaDescription = metaDescription.replace(/(\r\n|\n|\r)/gm, '');
      return metaDescription.substring(0, 128);
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      s = s.toLowerCase();

      return s.replace(/\b\w/g, l => l.toUpperCase());
    },
    fetchForm(title) {
      fetch('https://maquinariareig.com/wp-json/my-route/my-phrase')
        .then(response => response.json())
        .then((form) => {
          const modal = document.querySelector('.modal');
          if (modal) {
            modal.remove();
          }
          const modalBackdrop = document.querySelector('.modal-backdrop');
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
          Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
          const el = document.createElement('div');
          el.id = 'modalWrapper';
          document.body.appendChild(el);

          el.innerHTML = `${
            '<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">'
              + '<div class="modal-dialog">'
              + '<div class="modal-content">'
              + '<div class="modal-header">'
              + '<h4 class="modal-title">'
              + 'Consulta sobre '
          }${title}</h4>`
            + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            + '</div>'
            + `<div class="modal-body">${form}</div>`
            + '</div>'
            + '</div>'
            + '</div>';

          document.getElementsByName('your-maquina')[0].value = title;
          const formModal = document.getElementById('wpcf7-f3-o1');
          formModal.action = 'https://maquinariareig.com/wp-json/contact-form-7/v1/contact-forms/3/feedback';

          function addSubscriber() {
            const checkbox = document.getElementById('newsletter');
            if (checkbox.checked) {
              const email = document.querySelector('[name="your-email"]');
              if (email) {
                const url = 'https://maquinariareig.com/wp-json/newsletter/v2/subscribers?client_key=4ebb5b2b007eaa28da0b123e94c5eb5aa7cd9ad9&client_secret=d91a22294d77af87e9e67266e322d78d13c088d5';
                const data = {
                  email: email.value,
                  status: 'confirmed'
                };

                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data)
                })
                  .then(response => response.json())
                  .then(data => console.log('Success:', data))
                  .catch((error) => console.error('Error:', error));
              }
            }
          }

          formModal.addEventListener('submit', (evt) => {
            evt.preventDefault();
            const url = formModal.action;
            const formData = new FormData(document.querySelector('form'));

            fetch(url, {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then((data) => {
                if (data.status === 'mail_sent') {
                  // if (window.ga) {
                  //   window.ga("send", "event", "Formulari", "submit");
                  // }

                  if (this.$gtag) {
                    this.$gtag.event('send', { formulari: 'submit' });
                  }

                  const modal = document.querySelector('.modal');
                  if (modal) {
                    modal.remove();
                  }
                  const modalBackdrop = document.querySelector('.modal-backdrop');
                  if (modalBackdrop) {
                    modalBackdrop.remove();
                  }
                  Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
                } else {
                  const oldErrors = document.querySelectorAll('.form-error');
                  for (let i = 0; i < oldErrors.length; i++) {
                    oldErrors[i].remove();
                  }

                  if (data.invalid_fields) {
                    for (let i = 0; i < data.invalid_fields.length; i++) {
                      const field = document.querySelector(`[name=${data.invalid_fields[i].field}]`);
                      if (field) {
                        const divElement = document.createElement('div');
                        divElement.className = 'form-error';
                        divElement.innerHTML = data.invalid_fields[i].message;
                        field.insertAdjacentElement('afterend', divElement);
                      }
                    }
                  }
                }
              });

            addSubscriber()
          });
        })
        .catch(error => console.error(error));
    },
  },
};
</script>

<style>
.description .gallery {
  display: none;
}

.producte img {
  float: left;
  max-width: 100%;

  max-width: 47%;
}

@media only screen and (max-width: 800px) {
  .producte img {
    max-width: 100%;
  }
}

.modal-slideshow img {
  float: none;
}

.description {
  width: 90%;
  min-width: 275px;
  min-height: 250px;
}

.description img {
  display: none;
}

.description table {
  max-width: 100% !important;
  width: 100%;
}

.description iframe,
.description video,
.description div {
  max-width: 100%;
}
.description h2 {
  font-size: 1.3rem;
}

.paragraph-wrapper {
  width: 100px;
  min-height: 250px;
  overflow: auto;
}
</style>
