<template>
  <div class="contact-header">
    <div class="contact-header__subsection">
      <!-- <img class="contact-header__icon--mail" alt="mail" width="50px" src="https://maquinariareig.com/wp-content/uploads/2019/01/icon-mail2.png" /> -->
      <div class="contact-header__email" data-toggle="modal" data-target="#myModal">
        <img
          class="contact-header__icon--mail"
          alt="mail"
          width="50px"
          src="https://maquinariareig.com/wp-content/uploads/2020/05/hiclipart.com_-150x150.png"
        />
        reig@maquinariareig.com
      </div>
    </div>
    <div class="contact-header__subsection">
      <div class="contact-header__phone">
        <a href="tel:619224111">619224111</a> / <a href="tel:620684055">620684055</a>
        <img
          class="contact-header__icon--whatsapp"
          alt="whatsapp"
          width="50px"
          src="https://maquinariareig.com/wp-content/uploads/2020/05/whatsapp-icon-1.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContactHeader',
};
</script>
<style lang="scss">
.contact-header {
  // background: -moz-linear-gradient(271deg, rgba(52, 58, 64, 1) 0%, rgba(52, 58, 64, 1) 63%, rgba(153, 144, 144, 1) 100%);
  // /* ff3.6+ */
  // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(52, 58, 64, 1)), color-stop(63%, rgba(52, 58, 64, 1)), color-stop(100%, rgba(153, 144, 144, 1)));
  // /* safari4+,chrome */
  // background: -webkit-linear-gradient(271deg, rgba(52, 58, 64, 1) 0%, rgba(52, 58, 64, 1) 63%, rgba(153, 144, 144, 1) 100%);
  // /* safari5.1+,chrome10+ */
  // background: -o-linear-gradient(271deg, rgba(52, 58, 64, 1) 0%, rgba(52, 58, 64, 1) 63%, rgba(153, 144, 144, 1) 100%);
  // /* opera 11.10+ */
  // background: -ms-linear-gradient(271deg, rgba(52, 58, 64, 1) 0%, rgba(52, 58, 64, 1) 63%, rgba(153, 144, 144, 1) 100%);
  // /* ie10+ */
  // background: linear-gradient(179deg, rgba(52, 58, 64, 1) 0%, rgba(52, 58, 64, 1) 63%, rgba(153, 144, 144, 1) 100%);
  /* ie6-9 */
  //box-shadow: -1px 3px 1px 1px #ada4a4;
  color: #fff;
  position: relative;
  min-height: 45px;
  background: #343a40;
  border-radius: 0 0 5px 5px;
  // opacity: 0.95;

  .contact-header__icon--whatsapp {
    position: absolute;
    top: -15px;
    right: 5%;
  }
  .contact-header__icon--mail {
    position: absolute;
    left: 5%;
    top: -15px;
  }
  .contact-header__phone {
    font-size: 1.2rem;
    display: inline-block;
    padding-left: 1rem;

    a {
      color: gold;
    }
  }
  .contact-header__email {
    font-size: 1.2rem;
    display: inline-block;
    color: gold;
    cursor: pointer;
  }
}

.contact-header__subsection {
  width: 48%;
  display: inline-block;
  text-align: left;
  padding-left: 110px;
  padding-right: 0;
  position: relative;

  &:last-child {
    text-align: right;
    padding-left: 0;
    padding-right: 110px;
  }
}

@media only screen and (max-width: 730px) {
  .contact-header {
    min-height: 80px;

    .contact-header__subsection {
      width: 100%;
      padding-left: 65px;

      &:last-child {
        padding-right: 65px;
      }

      .contact-header__phone {
        padding-top: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .contact-header {
    .contact-header__icon--whatsapp {
      top: 5px;
      width: 35px;
    }
    .contact-header__icon--mail {
      top: 5px;
      width: 35px;
    }
    .contact-header__phone {
      font-size: 1rem;
      padding-top: 0.5rem;
    }
    .contact-header__email {
      font-size: 1rem;
      padding-top: 0.5rem;
    }
  }
}

@media only screen and (max-width: 650px) {
  .contact-header {
    height: 65px;
    margin-top: -1px;
    .contact-header__phone {
      font-size: 12px;
      display: block;
      padding-left: 0;
    }
    .contact-header__email {
      font-size: 12px;
      display: block;
    }
  }
}
</style>
