import Vue from 'vue';
import Router from 'vue-router';
import Meta from 'vue-meta';
import Home from './views/Home.vue';
import NewMachines from './views/NewMachines.vue';
import OldMachines from './views/OldMachines.vue';
import Product from './views/Product.vue';
import Category from './views/Category.vue';
import Search from './views/Search.vue';
import Layout from './views/Layout.vue';

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: 'history',
  base: '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      component: Layout,
      path: '/',
      children: [
        {
          name: 'main',
          path: '/',
          component: Home,
          meta: { title: 'Maquinaria Reig' },
        },
        {
          name: 'category',
          path: '/categoria/:parentCategory/:childCategory',
          component: Category,
          meta: { title: 'Maquinaria Reig' },
        },
        {
          name: 'maquinaria-ocasion',
          path: '/maquinaria-ocasion/categorias',
          component: OldMachines,
          meta: { title: 'Maquinaria de Ocasion' },
        },
        {
          name: 'maquinaria-nueva',
          path: '/maquinaria-nueva/categorias',
          component: NewMachines,
          meta: { title: 'Maquinaria Nueva' },
        },
        {
          name: 'producte',
          path: '/producte/maquina/:productId/:name?',
          component: Product,
          meta: { title: 'Maquina' },
        },
        {
          name: 'search',
          path: '/search/:searchParam',
          component: Search,
          meta: { title: 'Maquinaria Reig | Busqueda' },
        },
        {
          path: '*',
          redirect: '/',
        },
      ],
    },
  ],
});
