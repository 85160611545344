<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" id="menu">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <!--          <img-->
          <!--            height="60"-->
          <!--            src="https://maquinariareig.com/wp-content/uploads/2023/09/mini-logo.png"-->
          <!--            alt="MAQUINARIA REIG"-->
          <!--          />-->
        </li>
        <li class="nav-item active dropdown" data-id="maquinaria-nova2">
          <h2
            class="nav-link dropdown-toggle"
            id="navbarDropdown2"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Maquinaria Nueva
          </h2>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
            <router-link class="dropdown-item" to="/maquinaria-nueva/categorias"
              >Maquinaria Nueva</router-link
            >
            <div class="dropdown-divider"></div>
            <router-link
              v-for="(newCategory, index) in newCategories"
              :key="index"
              class="dropdown-item"
              :to="{
                name: 'category',
                params: { parentCategory: 'maquinaria-nueva', childCategory: newCategory.slug },
              }"
              >{{
                newCategory.name[0].toUpperCase() + newCategory.name.slice(1).toLowerCase()
              }}</router-link
            >
          </div>
        </li>
        <li class="nav-item dropdown" data-id="maquinaria-ocasion">
          <h2
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Maquinaria de Ocasion
          </h2>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link class="dropdown-item" to="/maquinaria-ocasion/categorias"
              >Maquinaria de Ocasion</router-link
            >
            <div class="dropdown-divider"></div>
            <router-link
              v-for="(oldCategory, index) in oldCategories"
              :key="index"
              class="dropdown-item"
              :to="{
                name: 'category',
                params: { parentCategory: 'maquinaria-ocasion', childCategory: oldCategory.slug },
              }"
              >{{
                oldCategory.name[0].toUpperCase() + oldCategory.name.slice(1).toLowerCase()
              }}</router-link
            >
          </div>
        </li>
      </ul>
      <input
        class="form-control search-input mr-sm-2"
        v-model="searchValue"
        type="search"
        placeholder="Buscar"
        aria-label="Search"
        data-search
      />
    </div>
  </nav>
</template>

<script>
export default {
  name: 'CategoryRectangle',
  props: {
    productId: Number,
    name: String,
    avatar: String,
  },
  data() {
    return {
      searchValue: '',
    };
  },
  mounted() {
    const _this = this;

    document.querySelector('[data-search]').addEventListener('keyup', (e) => {
      const code = e.keyCode || e.which;
      if (code === 13) {
        if (_this.search) {
          _this.search();
          document.querySelector('[data-search]').blur();
        }
      }
    });
  },
  computed: {
    newCategories() {
      const output = [];
      const keys = [];

      this.$store.state.newCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
    oldCategories() {
      const output = [];
      const keys = [];

      this.$store.state.oldCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
    search() {
      this.$router.push({ name: 'search', params: { searchParam: this.searchValue } });
    },
  },
};
</script>

<style>
.nav-item h2 {
  margin: 0.5rem;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  text-align: center;
  font-weight: 100;
  color: #fff;
  cursor: pointer;
  font-size: 1.15em;
  font-weight: 500;
  letter-spacing: 1.2px;
}

.nav-item.dropdown {
  white-space: nowrap;
}

.search-input {
  max-width: 400px;
}
</style>
