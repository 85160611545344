<template>
  <div>
    <header>
      <div id="logo-container">
        <router-link to="/">
          <transition-group name="header-animation">
            <!-- <img :src="headerImage" :key="headerImage" alt="MAQUINARIA REIG BACKGROUND" /> -->
            <picture v-show="!maquinariaNueva" key="ocasion">
              <!--                  <source type="image/avif" srcset="/maquinaria/header.avif">-->
              <source
                type="image/jpg"
                srcset="https://maquinariareig.com/wp-content/uploads/2018/10/header.png"
              />
              <img
                src="https://maquinariareig.com/wp-content/uploads/2018/10/header.png"
                alt="MAQUINARIA REIG OCASION"
              />
            </picture>

            <picture v-show="maquinariaNueva" key="nueva">
              <!--                  <source type="image/avif" srcset="/maquinaria/header-nova.avif">-->
              <source
                type="image/jpg"
                srcset="https://maquinariareig.com/wp-content/uploads/2019/01/header-nova.jpg"
              />
              <img
                v-show="maquinariaNueva"
                src="https://maquinariareig.com/wp-content/uploads/2019/01/header-nova.jpg"
                alt="MAQUINARIA REIG NUEVA"
              />
            </picture>

            <!--                <img v-show="maquinariaNueva" src="https://maquinariareig.com/wp-content/uploads/2019/01/header-nova.jpg" key="nueva" alt="MAQUINARIA REIG NUEVA" />-->
            <img
              key="nueva-logo"
              class="logo-background"
              v-show="maquinariaNueva"
              src="https://maquinariareig.com/wp-content/uploads/2019/01/logo-header.jpg"
              alt="MAQUINARIA REIG"
            />
          </transition-group>
        </router-link>
      </div>
    </header>
    <custom-menu></custom-menu>
    <contact-header></contact-header>
    <div class="child-view-wrapper">
      <transition :name="transitionName">
        <keep-alive>
          <router-view :key="$route.fullPath" class="child-view"> </router-view>
        </keep-alive>
      </transition>
    </div>

    <custom-footer></custom-footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import Menu from '@/components/Menu';
import ContactHeader from '@/components/ContactHeader';

export default {
  components: {
    'custom-menu': Menu,
    'contact-header': ContactHeader,
    'custom-footer': Footer,
  },
  name: 'Layout',
  metaInfo: {
    meta: [
      { charset: 'utf-8' },
      {
        vmid: 'description',
        name: 'description',
        content:
          '➜ En Maquinaria Reig ⚡ encontrará toda la Maquinaria metalurgica que busque, ya sea de ocasion, Nueva o de Segunda Mano. ✅ No dude en solicitarnos presupuesto',
      },
    ],
  },
  data() {
    return {
      transitionName: 'slide-left',
    };
  },
  computed: {
    maquinariaNueva() {
      return this.$route.path.indexOf('maquinaria-nueva') > -1;
    },
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    },
  },
};
</script>

<style lang="scss">
#logo-container {
  min-height: 125px;
}
.header-animation-enter-active {
  transition: opacity 1s ease;
}
.header-animation-leave-active {
  display: none;
  transition: opacity 0.5s ease 0.1s;
}
.header-animation-enter,
.header-animation-leave-active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.child-view {
  position: relative;
  transition: all 0.7s ease-in-out; //cubic-bezier(.55,0,.1,1);
  max-width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
  min-height: 100vh;
  width: 100%;
}

.child-view-wrapper {
  min-height: 60vh;
}

.slide-left-enter,
.slide-right-leave-active {
  position: absolute;
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0);
}
.slide-left-leave-active,
.slide-right-enter {
  position: absolute;
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0);
}

.slide-right-leave-active .loader,
.slide-left-leave-active .loader {
  display: none !important;
}

footer span {
  font-size: 0.8rem;
}

.logo-background {
  position: absolute;
  top: 2%;
  left: 5%;
  width: 200px;
  opacity: 0.6;
  object-fit: contain;
}

h1 {
  font-size: 1.8rem !important;
}
</style>
