<template>
  <div>
    <h1>{{ category }}</h1>
    <div class="card-deck">
      <product-square
        v-for="(producte, index) in productes"
        :key="$route.params.childCategory + index"
        :name="producte.name"
        :avatar="producte.avatar"
        :productId="producte.productId"
      ></product-square>
    </div>
    <loader v-if="!productes.length || loadingProducts"></loader>
  </div>
</template>

<script>
import ProductSquare from '@/components/ProductSquare';
import Loader from '@/components/Loader';

export default {
  name: 'Category',
  components: {
    'product-square': ProductSquare,
    Loader,
  },
  metaInfo() {
    return {
      title: `→ ${this.title}`,
      meta: [{ name: 'description', content: this.$route.params.childCategory }],
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `En Maquinaria Reig encontrara ${this.title} y mucho mas! ✅ Consulta nuestro catalogo de maquinaria de ocasion, nueva y segunda mano. No dude en solicitarnos presupuesto.`,
        },
      ],
    };
  },
  computed: {
    loadingProducts() {
      return this.$store.state.loadingProducts;
    },
    category() {
      const name = this.$route.params.childCategory;
      if (name) {
        let upperCasedName = name.charAt(0).toUpperCase() + name.slice(1);
        upperCasedName = upperCasedName.replace('maquinaria-nueva', ' ');
        upperCasedName = upperCasedName.trim();
        return (
          upperCasedName.replace(/-/g, ' ')
          + (this.$route.params.parentCategory === 'maquinaria-nueva'
            ? ' al mejor Precio'
            : ' de Ocasion')
        );
      }

      return '';
    },
    title() {
      return this.category;
    },
    productes() {
      const context = this;
      const productes = [];

      this.$store.state.products.forEach((post) => {
        if (
          post.parentCategory.slug === context.$route.params.parentCategory
          && post.category.slug === context.$route.params.childCategory
        ) {
          productes.push(post);
        }
      });

      return productes;
    },
  },
};
</script>

<style>
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  position: absolute;
  animation: bounce-in 0.05s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>
