<template>
  <div class="card rectangle">
    <router-link
      :to="{ name: 'category', params: { parentCategory: parentCategory, childCategory: slug } }"
    >
      <div class="avatar-wrapper">
        <img
          loading="lazy"
          width="480px"
          height="480px"
          :src="avatar"
          :alt="name"
          class="card-img-top img-thumbnail"
        />
      </div>

      <p class="card-text">
        {{ name }}
      </p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CategoryRectangle',
  props: {
    name: String,
    avatar: String,
    slug: String,
    parentCategory: String,
  },
  computed: {
    loadingProducts() {
      return this.$store.state.loadingProducts;
    },
  },
};
</script>

<style scoped lang="scss">
.card-text {
  //float: right;
  background: none;
  font-size: 25px;
  text-transform: uppercase;
  text-align: center;
  //position: absolute;
  //top: 40%;
  //transform: translateY(-50%);
  //max-width: 65%;
  //right: 2%;
  //left: auto;

  /*
    background: none;
    font-size: 25px;
    max-width: 65%;
    right: 2%;
    top: 10%;*/
}

.rectangle .avatar-wrapper {
  //float: left;
  background: #fff;
}

.rectangle img {
  min-width: 250px;
  margin-bottom: 0;
  // border: 2px solid #fce8c4;
  width: 8em;
  /*
  border: 1px solid #ff2300;
  box-shadow: 0 0 10px #ff2300; */
}

.rectangle {
  /*background: #ececec;*/
  width: auto;
  max-width: 450px;
  margin: 1.5em 24px;
  padding-left: 0;
  background: white;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.rectangle a {
  padding-bottom: 0;
}

.rectangle .card-text {
  background: rgba(255, 255, 255, 0.9);
}

.rectangle:hover {
  transform: none;
  transform: scale(1.05);
}

@media only screen and (max-width: 500px) {
  .card-text {
    //max-width: 50%;
    font-size: 1.25rem;
  }
}
</style>
