<template>
  <div>
    <h1>MAQUINARIA REIG</h1>
    <h2 class="subtitle">Maquinaria Metalurgica Nueva, de Ocasion y de segunda mano</h2>
    <div class="card-deck main-deck fade-in" style="flex-flow: unset;">
      <router-link :to="{ name: 'maquinaria-ocasion' }" class="card">
        <div class="card-body">
          <p class="card-text main-link ocasion-link">Maquinaria de ocasion</p>
        </div>
        <img
          class="card-img-top img-thumbnail"
          src="https://maquinariareig.com/wp-content/uploads/2023/09/IMG_8134.jpg"
          alt="Maquinaria de ocasion"
        />
      </router-link>

      <router-link :to="{ name: 'maquinaria-nueva' }" class="card">
        <div class="card-body">
          <p class="card-text main-link">Maquinaria nueva</p>
        </div>
        <img
          class="card-img-top img-thumbnail"
          src="https://maquinariareig.com/wp-content/uploads/2018/12/TORNO-HELLER-CE460X1500.png"
          alt="Maquinaria Nueva"
        />
      </router-link>
    </div>

    <h2 class="subtitle">¿Quieres Vender tu Maquinaria Metalurgica?</h2>
    <p class="about-us">
      Compramos tu Maquinaria Metalurgica, contactanos para vender tu maquinaria metalurgica usada y
      de segunda mano
    </p>

    <div class="card-deck main-deck" style="flex-flow: unset;">
      <span>
        <div>
          <button type="button" data-toggle="modal" data-target="#myModal">
            COMPRAMOS MAQUINARIA METALURGICA
          </button>
        </div>
      </span>
    </div>

    <div class="destacados">
      <h2 class="subtitle">Oportunidades</h2>

      <div class="card bg-light border-dark mb-3">
        <a href="https://maquinariareig.com/producte/maquina/5940/RODILLO-CURVADOR-DAVI-3000X20" class=""
          ><div class="card-body">
            <p class="card-text">
              Cilindro curvador Davi 4 rodillos 3000x20
            </p>
          </div>
          <img
            src="https://maquinariareig.com/wp-content/uploads/2023/10/IMG_2782-480x480.jpg"
            loading="lazy"
            width="480px"
            height="480px"
            alt="Cilindro curvador Davi"
            class="card-img-top img-thumbnail"
        /></a>
      </div>
    </div>

    <h2 class="subtitle">Nuestra Maquinaria De Segunda Mano y de Ocasion</h2>
    <div class="row">
      <category-rectangle
        v-for="(oldCategory, index) in oldCategories"
        :key="index"
        :name="oldCategory.name"
        :parentCategory="'maquinaria-ocasion'"
        :avatar="oldCategory.avatar"
        :slug="oldCategory.slug"
      >
      </category-rectangle>
    </div>

    <h2 class="subtitle subtitle-new">Nuestra Maquinaria Nueva</h2>
    <div class="row">
      <category-rectangle
        v-for="(newCategory, index) in newCategories"
        :key="index"
        :name="newCategory.name"
        :avatar="newCategory.avatar"
        :parentCategory="'maquinaria-nueva'"
        :slug="newCategory.slug"
      >
      </category-rectangle>
    </div>

    <h2 class="about-title">Sobre Nosotros</h2>
    <p class="about-us">
      En Maquinaria Reig llevamos años dedicándonos a la compra y venta de
      <strong>maquinaria metalurgica</strong> y <strong>maquinaria industrial</strong> lo que nos
      convierte en unos expertos del sector.
      <!-- </br></br> -->
      Nuestra experiencia y compromiso con nuestros clientes es la base de nuestro trabajo diario.
      <!-- </br></br> -->
      Buscamos la satisfacción de nuestros clientes por eso queremos ofreceros nuestro extenso
      catalogo que incluye más de 250 máquinas y donde podra encontrar todas las máquinas que busque
      tanto
      <strong>nuevas</strong>, de <strong>ocasion</strong> o de <strong>segunda mano</strong> que
      ofrecemos.
      <!-- </br></br> -->
      También
      <strong>compramos maquinaria</strong> por lo que si tienes algo que ofrecer no dudes en
      contactar con nosotros.
    </p>

    <script type="application/ld+json">
      {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Maquinaria Reig",
        "image": "https://maquinariareig.com/wp-content/uploads/2018/10/header.png",
        "@id": "https://maquinariareig.com",
        "url": "https://maquinariareig.com",
        "telephone": "619224111",
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Polígon Industrial de Pont-Xetmar, Carrer H, Nave 5",
          "addressLocality": "Cornella del Terri",
          "postalCode": "17844,",
          "addressCountry": "ES"
        },
        "review": {
          "@type": "Review",
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "4.7",
            "bestRating": "5",
            "worstRating": "1",
            "ratingCount": "14"
          },
          "author": {
            "@type": "Person",
            "name": "Lillian Ruiz"
          }
        }
      }
    </script>
  </div>
</template>

<script>
import CategoryRectangle from '@/components/CategoryRectangle';

export default {
  components: {
    'category-rectangle': CategoryRectangle,
  },
  name: 'home',
  metaInfo: {
    link: [{ rel: 'canonical', href: 'https://maquinariareig.com' }],
  },
  data() {
    return {
      formUpdated: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fetchForm('Consulta a Maquinaria Reig');
    });
  },
  computed: {
    newCategories() {
      const output = [];
      const keys = [];

      this.$store.state.newCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
    oldCategories() {
      const output = [];
      const keys = [];
      this.$store.state.oldCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
  },
  methods: {
    fetchForm(title) {
      fetch('https://maquinariareig.com/wp-json/my-route/my-phrase')
        .then(response => response.json())
        .then((form) => {
          const modal = document.querySelector('.modal');
          if (modal) {
            modal.remove();
          }
          const modalBackdrop = document.querySelector('.modal-backdrop');
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
          Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
          const el = document.createElement('div');
          el.id = 'modalWrapper';
          document.body.appendChild(el);

          el.innerHTML = `${
            '<div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModal" aria-hidden="true">'
              + '<div class="modal-dialog">'
              + '<div class="modal-content">'
              + '<div class="modal-header">'
              + '<h4 class="modal-title">'
          }${title}</h4>`
            + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
            + '</div>'
            + `<div class="modal-body">${form}</div>`
            + '</div>'
            + '</div>'
            + '</div>';

          document.getElementsByName('your-maquina')[0].value = title;
          const formModal = document.getElementById('wpcf7-f3-o1');
          formModal.action = 'https://maquinariareig.com/wp-json/contact-form-7/v1/contact-forms/3/feedback';

          function addSubscriber() {
            const checkbox = document.getElementById('newsletter');
            if (checkbox.checked) {
              const email = document.querySelector('[name="your-email"]');
              if (email) {
                const url = 'https://maquinariareig.com/wp-json/newsletter/v2/subscribers?client_key=4ebb5b2b007eaa28da0b123e94c5eb5aa7cd9ad9&client_secret=d91a22294d77af87e9e67266e322d78d13c088d5';
                const data = {
                  email: email.value,
                  status: 'confirmed'
                };

                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify(data)
                })
                  .then(response => response.json())
                  .then(data => console.log('Success:', data))
                  .catch((error) => console.error('Error:', error));
              }
            }
          }

          formModal.addEventListener('submit', (evt) => {
            evt.preventDefault();
            const url = formModal.action;
            const formData = new FormData(document.querySelector('form'));

            fetch(url, {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then((data) => {
                if (data.status === 'mail_sent') {
                  // if (window.ga) {
                  //   window.ga("send", "event", "Formulari", "submit");
                  // }

                  if (this.$gtag) {
                    this.$gtag.event('send', { formulari: 'submit' });
                  }

                  const modal = document.querySelector('.modal');
                  if (modal) {
                    modal.remove();
                  }
                  const modalBackdrop = document.querySelector('.modal-backdrop');
                  if (modalBackdrop) {
                    modalBackdrop.remove();
                  }
                  Array.from(document.querySelectorAll('body.modal-open')).forEach(el => el.classList.remove('modal-open'));
                } else {
                  const oldErrors = document.querySelectorAll('.form-error');
                  for (let i = 0; i < oldErrors.length; i++) {
                    oldErrors[i].remove();
                  }
                  if (data.invalid_fields) {
                    for (let i = 0; i < data.invalid_fields.length; i++) {
                      const field = document.querySelector('[name=' + data.invalid_fields[i].field + ']');
                      if (field) {
                        const divElement = document.createElement('div');
                        divElement.className = 'form-error';
                        divElement.innerHTML = data.invalid_fields[i].message;
                        field.insertAdjacentElement('afterend', divElement);
                      }
                    }
                  }
                }
              });

            addSubscriber();
          });
        })
        .catch(error => console.error(error));
    },
  },
};
</script>

<style scoped>
.subtitle {
  font-size: 1.8rem;
}

.subtitle.subtitle-new {
  border-top: 2px solid black;
  padding-top: 2rem;
}

.about-title {
  font-size: 1rem;
  margin-top: 4rem;
}

.destacados {
  max-width: 90vw;
  margin: 0 auto;
}

.about-us {
  max-width: 600px;
  margin: 0 auto;
  font-size: 1rem;
  color: #696868;
  margin-bottom: 3rem;
  padding: 0 0.5rem;
}
.review-snippet {
  font-size: 12px;
  color: #d3d3d3;
}

.destacados .card {
  max-width: 400px;
}

@media only screen and (max-width: 500px) {
  .card-deck.main-deck .card .card-text {
    padding-top: 0.5rem;
  }
}
</style>
