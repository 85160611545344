<template>
  <div class="card bg-light border-dark mb-3">
    <router-link :to="{ name: 'producte', params: { productId: productId, name: cleanName } }">
      <div class="card-body">
        <p class="card-text">
          {{ name }}
        </p>
      </div>
      <img
        :src="avatar"
        loading="lazy"
        width="480px"
        height="480px"
        :alt="name"
        class="card-img-top img-thumbnail"
      />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProductSquare',
  props: {
    productId: Number,
    name: String,
    avatar: String,
  },
  computed: {
    cleanName() {
      const cleanName = this.name.replace(/\s/g, '-');
      return cleanName.replace('/', '-');
    },
  },
};
</script>
