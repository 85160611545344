<template>
  <div>
    <h1>MAQUINARIA OCASION</h1>
    <div class="row">
      <category-rectangle
        v-for="(oldCategory, index) in oldCategories"
        :key="index"
        :name="oldCategory.name"
        :parentCategory="'maquinaria-ocasion'"
        :avatar="oldCategory.avatar"
        :slug="oldCategory.slug"
      ></category-rectangle>
    </div>
    <loader v-if="!oldCategories.length || loadingProducts"></loader>
  </div>
</template>

<script>
import CategoryRectangle from '@/components/CategoryRectangle';
import Loader from '@/components/Loader';

export default {
  components: {
    'category-rectangle': CategoryRectangle,
    Loader,
  },
  name: 'OldMachines',
  metaInfo: {
    title: 'Maquinaria de Ocasion',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'En Maquinaria Reig encontrará toda la maquinaria metalurgica de ocasion y de segunda mano que busque. No dude en solicitarnos presupuesto.',
      },
    ],
  },
  computed: {
    loadingProducts() {
      return this.$store.state.loadingProducts;
    },
    oldCategories() {
      const output = [];
      const keys = [];

      this.$store.state.oldCategories.forEach((post) => {
        const key = post.name;

        if (keys.indexOf(key) === -1) {
          keys.push(key);
          output.push(post);
        }
      });

      return output;
    },
  },
};
</script>
